<template>
  <section class="page-section">
    <div class="side-by-side">
      <img
        class="pixel-art rocket"
        alt="rocket taking off"
        src="../assets/image/rocket.png"
      />
      <div>
        <h1 class="headline">Contact &amp; links</h1>
        <div style="margin-left: 1rem">
          <div class="list-line">
            <img
              class="pixel-art rocket-point"
              alt="small rocket"
              src="../assets/image/rocket_small.png"
            />
            <p class="list-item">
              Find me on
              <a href="https://github.com/2000Dobby">GitHub</a>
            </p>
          </div>
          <div class="list-line">
            <img
              class="pixel-art rocket-point"
              alt="small rocket"
              src="../assets/image/rocket_small.png"
            />
            <p class="list-item">
              Write me an
              <a href="mailto://dobey2003@gmail.com">E-Mail</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.headline {
  margin-bottom: 2rem;
}

.side-by-side {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.rocket-point {
  width: 2rem;
  height: auto;
  margin-right: 1rem;
}

.rocket {
  width: calc(10rem + 5vw);
  max-width: 14rem;
  height: auto;
}
</style>
