<template>
  <section class="page-section">
    <div class="title-container">
      <img
        class="pixel-art pointer"
        alt="Avatar"
        src="../assets/image/avatar.png"
        style="width: calc(3vw + 7rem)"
      />
      <div>
        <div style="display: flex; align-items: center">
          <h1 class="title" style="margin-right: 0.3em">&gt;</h1>
          <div><h1 class="title typing" style="--steps: 7">2kDobby</h1></div>
        </div>
        <div style="display: flex; margin-top: 0.5rem">
          <div>
            <h2
              style="
                font-size: calc(1vw + 0.6rem);
                color: turquoise;
                text-shadow: 0 0 12px turquoise;
              "
            >
              Computer Science Student
            </h2>
          </div>
        </div>
      </div>
    </div>
    <img
      alt="Arrow"
      class="pixel-art button-down pointer"
      src="../assets/image/arrow.png"
      style="
        width: calc(2vw + 3rem);
        filter: drop-shadow(0px 0px 12px #9892ff);
        position: absolute;
        bottom: 0;
      "
    />
  </section>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.title-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
